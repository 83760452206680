.redemption-form h2 {
  font-family: 'Aptly', sans-serif;
  color: white;
  font-size: 1.5rem;
  text-transform: uppercase;
  padding: 1rem 0 0.5rem 0;
}

.redemption-form input,
.redemption-form select {
  margin-bottom: 0.5rem;
}

.redemption-form label,
.redemption-form input,
.redemption-form select {
  font-family: 'GillSans', sans-serif;
  color: white;
  font-size: 1rem;
}

.redemption-form label {
  display: inline-block;
  min-width: 30%;
}

.redemption-form input[type='text'],
.redemption-form input[type='email'],
.redemption-form select {
  border: 1px solid #cfab5e;
  border-radius: 0.3rem;
  padding: 0.5rem;
  background: #1e2807;
  display: inline-block;
  width: 60%;
}

.redemption-form input[type='radio'] {
  all: revert; /* Undo reset.css styles */
  accent-color: #cfab5e;
}

.redemption-form input:placeholder,
.redemption-form select option:first-child {
  color: #a4a89b;
}

.redemption-form input[type='submit'] {
  padding: 0.7rem 1.2rem;
  background: #cfab5e;
  border-radius: 0.3rem;
  text-transform: uppercase;
  color: #1e2807;
  cursor: pointer;
  transition: background 0.2s ease-in-out;
}

.redemption-form input[type='submit']:hover {
  background: #d4b36e;
  transition: background 0.2s ease-in-out;
}

@media screen and (min-width: 600px) {
  .redemption-form label {
    min-width: 100px;
  }

  .redemption-form input[type='text'],
  .redemption-form input[type='email'],
  .redemption-form select {
    width: 200px;
  }
}

.swiper.swiper-horizontal {
  width: 100%;
}

.swiper-slide {
  transition-property: width, scale, transform !important;
}

.collectible-carousel .swiper-wrapper {
  align-items: flex-start;
  padding-top: 40px;
  padding-bottom: 50px;
}

@media (min-width: 1280px) {
  .collectible-carousel .swiper-wrapper {
    padding-bottom: 60px;
  }
}

.ImageZoom {
  inset: 0;
  display: none;
}

.ImageZoom[open] {
  display: block;
}

[data-rmiz-modal-overlay='visible'] {
  background-color: rgba(0, 0, 0, 0.5) !important;
}
* {
  text-decoration: none;
}

html {
  font-family: 'GillSans', sans-serif;
}

body,
#root,
#__next {
  min-width: 100%;
}

body {
  height: 100%;
  max-height: 100vh;
  background: #2a3314;
}

::-webkit-scrollbar {
  width: 14px;
  height: 18px;
}

::-webkit-scrollbar-thumb {
  height: 6px;
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  background-color: #5d6746;
  -webkit-border-radius: 8px;
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}

.bar-of-progress {
  z-index: 500;
}

/* Remove Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 0s 600000s, color 0s 600000s;
}

/* Make sure CookiePro banner is clickable when dialog is open */
#onetrust-consent-sdk {
  position: relative !important;
  z-index: 2147483647 !important;
  pointer-events: auto !important;
}
